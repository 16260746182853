import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'

import RearrangeGrid from '../components/rearrange/rearrangeGrid'

import Layout from '../components/layout'
import SEO from '../components/seo'

import { Spinner, GridError } from '../components/grid/grid.styled'
import queryString from 'query-string'

const Rearrange = ({ data, location }) => {
  const [state, setState] = useState({
    isLoading: true,
    hasAccess: false
  })
  const siteTitle = data.site.siteMetadata.title

  useEffect(() => {
    const init = async () => {
      const { accessToken } = queryString.parse(location.search)
      if (accessToken) {
        setState({
          isLoading: false,
          hasAccess: true
        })
      } else {
        setState({
          isLoading: false,
          hasAccess: false
        })
      }
    }

    init()
  }, [])

  if (state.isLoading) {
    return (
      <Layout location={location} title={siteTitle} lang={'ar'}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Spinner />
        </div>
      </Layout>
    )
  }

  if (!state.hasAccess) {
    return (
      <Layout location={location} title={siteTitle} lang={'ar'}>
        <GridError>Access denied</GridError>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle} lang={'ar'}>
      <SEO title="Asharq" lang="ar" />
      <RearrangeGrid location={location} />
    </Layout>
  )
}

export default Rearrange

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
