import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import Grid from '../grid/grid'
import { getSize } from '../grid/helper'
import useViewportSize from '../../utils/useViewportSize'

const RearrangeGrid = ({
  location,
  articles,
  resetArticles,
  fetchRecommendations
}) => {
  const language = 'ar'
  const { width } = useViewportSize()

  useEffect(() => {
    // fetch page zero data
    fetchRecommendationsData({
      page: 0,
      refresh: false,
      gridSize: getSize(width)
    })
  }, [])

  const fetchRecommendationsData = ({
    page = articles.page,
    refresh,
    gridSize
  }) => {
    fetchRecommendations({
      page,
      gridLength: articles.data.length,
      lang: language,
      gridSize,
      refresh: refresh ? refresh : articles.firstRequest,
      isRearrange: true
    })
  }

  return (
    <Grid
      mode="rearrange"
      size={16}
      language={language}
      location={location}
      gridData={articles}
      key="rearrange-grid"
      fetchRecommendationsData={fetchRecommendationsData}
      resetArticles={resetArticles}
    />
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchRecommendations: (payload) => {
      dispatch({ type: 'ARTICLES_REQUESTED', payload })
    },
    resetArticles: () => {
      dispatch({ type: 'ARTICLES_RESET_REQUEST' })
    }
  }
}

const mapStateToProps = ({ articles }) => {
  return {
    articles
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RearrangeGrid)
